import React, { useEffect, useState } from "react";
import Main from "./main";

export default function Donation(props) {
  // const navigatePage = useNavigate();
  // Pages
  const [showAmount, setShowAmount] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [showMethod] = useState(false);
  const [showDonate] = useState(false);
  const [showThanks] = useState(false);
  const [showMain, setShowMain] = useState(false);

  //Amount
  const [donateAmount, setDonateAmount] = useState("");
  const [donateAmountErr, setDonateAmountErr] = useState("");

  // Details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [supportMessage, setSupportMessage] = useState("");
  const [checkRemain, setCheckRemain] = useState(false);

  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailIdErr, setEmailIdErr] = useState("");
  const [supportMessageErr] = useState("");
  const [checkRemainErr] = useState("");

  // Method
  const [loading] = useState(false);
  const [myDirectImpact, setMyDirectImpact] = useState(0);
  const [responseMessage] = useState("");

  const [finalDonate, setFinalDonate] = useState(0);

  // show Active Previous Tab
  const [paymentElementValidation, setPaymentElementValidation] =
    useState(false);

  useEffect(() => {
    if (showAmount) {
      localStorage.removeItem("chargedAmount:");
      if (localStorage.getItem("donateAmount") !== null) {
        setDonateAmount(localStorage.getItem("donateAmount"));
      }
    }

    if (showDetails) {
      if (localStorage.getItem("firstName") !== null) {
        setFirstName(localStorage.getItem("firstName"));
      }
      if (localStorage.getItem("lastName") !== null) {
        setLastName(localStorage.getItem("lastName"));
      }
      if (localStorage.getItem("emailId") !== null) {
        setEmailId(localStorage.getItem("emailId"));
      }
      if (localStorage.getItem("supportMessage") !== null) {
        setSupportMessage(localStorage.getItem("supportMessage"));
      }
      if (localStorage.getItem("checkRemain") !== null) {
        setCheckRemain(
          localStorage.getItem("checkRemain") === "true" ? true : false
        );
      }

      if (
        localStorage.getItem("donateAmount") === null ||
        localStorage.getItem("donateAmount") === ""
      ) {
        // navigatePage(`/`);
        localStorage.clear();
      }
    }

    if (showMethod) {
      if (localStorage.getItem("donateAmount") !== null) {
        setMyDirectImpact(parseInt(localStorage.getItem("donateAmount")));
        setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
      }
    }

    if (showDonate) {
      if (localStorage.getItem("donateAmount") !== null) {
        setMyDirectImpact(parseInt(localStorage.getItem("donateAmount")));
        setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
      }
    }
  }, []);

  const handleFormValidation = () => {
    let formIsValid = true;

    if (showDetails) {
      if (!firstName) {
        formIsValid = false;
        setFirstNameErr("First Name is required.");
      } else {
        localStorage.setItem("firstName", firstName);
        setFirstNameErr("");
      }

      if (!lastName) {
        formIsValid = false;
        setLastNameErr("Last Name is required.");
      } else {
        localStorage.setItem("lastName", lastName);
        setLastNameErr("");
      }

      if (!emailId) {
        formIsValid = false;
        setEmailIdErr("Email id is required.");
      } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(emailId)
      ) {
        formIsValid = false;
        setEmailIdErr("Invalid email id.");
      } else {
        localStorage.setItem("emailId", emailId);
        setEmailIdErr("");
      }
      return formIsValid;
    }

    if (showMethod) {
      return formIsValid;
    }

    if (showDonate) {
      setMyDirectImpact(parseInt(localStorage.getItem("donateAmount")));
      setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
    }
  };

  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      if (showAmount) {
        if (name === "donateAmount") {
          if (value.length !== 0) {
            setDonateAmount(value);
          } else {
            setDonateAmountErr("Please select amount");
          }
        }
      }

      if (showDetails) {
        if (name === "checkRemain") {
          setCheckRemain(e.target.checked);
          localStorage.setItem("checkRemain", e.target.checked);
        }
        if (name === "firstName") {
          setFirstName(value);
        }
        if (name === "lastName") {
          setLastName(value);
        }
        if (name === "emailId") {
          setEmailId(value);
        }
        if (name === "supportMessage") {
          setSupportMessage(value);
          localStorage.setItem("supportMessage", value);
        }
        handleFormValidation();
      }

    } else {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (showAmount) {
      if (donateAmount > 0 && donateAmount !== "") {
        localStorage.setItem("donateAmount", donateAmount);
        setShowAmount(false); setShowDetails(true);
        setFinalDonate(parseInt(donateAmount));
        if (localStorage.getItem("firstName") !== null) {
          setFirstName(localStorage.getItem("firstName"));
        }
        if (localStorage.getItem("lastName") !== null) {
          setLastName(localStorage.getItem("lastName"));
        }
        if (localStorage.getItem("emailId") !== null) {
          setEmailId(localStorage.getItem("emailId"));
        }
        if (localStorage.getItem("supportMessage") !== null) {
          setSupportMessage(localStorage.getItem("supportMessage"));
        }
        if (localStorage.getItem("checkRemain") !== null) {
          setCheckRemain(
            localStorage.getItem("checkRemain") === "true" ? true : false
          );
        }
      } else {
        setDonateAmountErr("Please select amount");
      }
    }

    if (showDetails) {
      if (handleFormValidation()) {
        setShowDetails(false); setShowMain(true);
        //navigatePage('/checkout');
      }
    }
  };

  const _onRadioChange = (amount) => {
    setDonateAmount(amount);
    setDonateAmountErr("");
  };

  return (
    <>
      {!showMain &&<div className="top-menu flex-display">
            <span
            className={
                showAmount
                ? "active "
                : (!showDetails && showMethod) ||
                    (showDetails && !showMethod) ||
                    (!showAmount && !showDetails && !showMethod)
                ? "is-tab-active"
                : ""
            }
            >
            1. Amount{" "}
            </span>
            <span
            className={
                showDetails
                ? "active "
                : (!showAmount && !showDetails && !showMethod) ||
                    (!showAmount && showMethod)
                ? "is-tab-active"
                : ""
            }
            >
            2. Details{" "}
            </span>
            <span
            className={
                showMethod
                ? "active "
                : !showAmount && !showDetails && !showMethod && showDonate
                ? "is-tab-active"
                : ""
            }
            >
            3.Method{" "}
            </span>
            <span
            className={showDonate || showThanks ? "active" : ""}
            >
            {" "}
            4. Donate{" "}
            </span>
        </div>}

      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}

      <div
        className={
          showDonate
            ? "flex-wrap flex-dire flex-bet flex-grow"
            : showThanks
            ? "flex-wrap flex-dire flex-bet flex-grow thankyou-bg"
            : "height100"
        }
      >
        <div
          className={
            showDonate
              ? "flex-wrap flex-dire flex-bet flex-grow height100"
              : "flex-wrap flex-dire flex-bet flex-grow height100"
          }
        >
          {showAmount && (
          <div className="head-title">
            <h1>Make a donation</h1>
            <p>Tap or add your donation amount.</p>
          </div>
          )}
          {showDetails && (
              <div className="head-title">
              <h1>Your details</h1>
              <p>Who’s making the donation?</p>
            </div>
          )}
          
          {responseMessage !== "" && (
            <p style={{ color: "red" }}>{responseMessage}</p>
          )}

          {showAmount && (
            <div className="">
              <div className="flex-wrap">
                <div className="selection mb10 border1px radius10px flex-display flex-dire justify-content flex-bet">
                  <input
                    id="30per"
                    name="hungry"
                    type="radio"
                    value={30}
                    onChange={(e) => _onRadioChange(e.target.value)}
                    checked={donateAmount === "30"}
                  />
                  <label htmlFor="30per" className="font-bold">
                    $30
                  </label>
                </div>
                <div className="selection mb10 border1px radius10px flex-display flex-dire justify-content flex-bet">
                  <input
                    id="70per"
                    name="hungry"
                    type="radio"
                    value={70}
                    onChange={(e) => _onRadioChange(e.target.value)}
                    checked={donateAmount === "70"}
                  />
                  <label htmlFor="70per" className="font-bold">
                    $70
                  </label>
                </div>
              </div>
              <div className="flex-wrap mb10">
                <div className="selection mb10 border1px radius10px flex-display flex-dire justify-content flex-bet">
                  <input
                    id="150per"
                    name="hungry"
                    type="radio"
                    value={150}
                    onChange={(e) => _onRadioChange(e.target.value)}
                    checked={donateAmount === "150"}
                  />
                  <label htmlFor="150per" className="font-bold">
                    $150
                  </label>
                </div>
                <div className="selection mb10 border1px radius10px flex-display flex-dire justify-content flex-bet">
                  <input
                    id="200per"
                    name="hungry"
                    type="radio"
                    value={200}
                    onChange={(e) => _onRadioChange(e.target.value)}
                    checked={donateAmount === "200"}
                  />
                  <label htmlFor="200per" className="font-bold">
                    $200
                  </label>
                </div>
              </div>

              <div className="flex-wrap flex-dire">
                <div
                  className={`field-group flex-display border1px radius10px overflow-hidden mb20 ${
                    donateAmountErr !== "" ? "showError" : ""
                  }`}
                >
                  <span className="input-group-text">AUD $</span>
                  <input
                    type="number"
                    name="donateAmount"
                    value={donateAmount}
                    placeholder="Add amount"
                    onChange={handleChange}
                    className="input-field border-none border-radius-none text-right font-bold-fild"
                  />
                </div>
                <div className="flex-display flex-gap-10">
                  Is my donation tax deductible?{" "}
                  <div className="info-bg"></div>
                </div>
              </div>
            </div>
          )}

          {showDetails && (
            <>
              <div className="flex-wrap">
                <div className="field-group col">
                  <label className="field-label" htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                    placeholder="Phil"
                    className={`input-field text-capital ${
                      firstNameErr ? "showError" : ""
                    }`}
                  />
                </div>

                <div className="field-group col">
                  <label className="field-label" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                    placeholder="Goodman"
                    className={`input-field text-capital ${
                      lastNameErr ? "showError" : ""
                    }`}
                  />
                </div>
              </div>

              <div className="flex-wrap flex-dire">
                <div className="field-group col mb5">
                  <label className="field-label" htmlFor="emailId">
                    Email
                  </label>
                  <input
                    type="text"
                    name="emailId"
                    value={emailId}
                    onChange={handleChange}
                    placeholder="phil@gmail.com"
                    className={`input-field ${emailIdErr ? "showError" : ""}`}
                  />
                </div>
                <div className="field-group col">
                  <textarea
                    type="text"
                    name="supportMessage"
                    value={supportMessage}
                    onChange={handleChange}
                    placeholder="Leave your message of support"
                    className={`input-field font-regular text-capital ${
                      supportMessageErr ? "showError" : ""
                    }`}
                  />
                </div>
              </div>

              <div className="flex-wrap flex-bet">
                <div className="">
                  <p>Remain anonymous</p>
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    hidden="hidden"
                    id="checkRemain"
                    name="checkRemain"
                    value={checkRemain}
                    onChange={handleChange}
                    className={`input-field ${
                      checkRemainErr ? "showError" : ""
                    }`}
                    checked={checkRemain}
                  />
                  <label className="switch" htmlFor="checkRemain"></label>
                </div>
              </div>
            </>
          )}


           {showMain && <>
            <Main></Main>
           </>}         


           {!showMain && <div className="flex-wrap flex-bet">
            {!showDonate && (
              <div className="">
                {showMethod && (
                  <button
                    className="org-btn org-btn-dis"
                    id="submit"
                    onClick={handleSubmit}
                    disabled={!paymentElementValidation}
                  >
                    continue
                  </button>
                )}
                {!showMethod && (
                  <button className="org-btn" onClick={handleSubmit}>
                    continue
                  </button>
                )}
              </div>
            )}

            {!showDonate && <div className="flex-wrap justify-content">
                <div className="app-logo-bg"></div>
            </div>}


            {showDonate && (
              <div className="width100">
                <button className="org-btn big-btn" onClick={handleSubmit}>
                  Donate ${finalDonate}
                </button>
              </div>
            )}
          </div>}

        </div>
      </div>
    </>
  );
}
