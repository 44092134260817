import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Find all widget divs
const widgetDivs = document.querySelectorAll('.little-phil-widget');

// Inject our React App into each class
widgetDivs.forEach(div => {
  ReactDOM.render(
    <React.StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} >
        <App symbol={div.dataset.symbol}/>  
      </GoogleReCaptchaProvider>
    </React.StrictMode>,
      div
  );
});



// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} >
//       <App />
//     </GoogleReCaptchaProvider>
//   </React.StrictMode>
// );

//reportWebVitals();