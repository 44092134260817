import React from 'react';
//import './App.css';
import Donation from './pages/donation';

  
function App(props) {
return (
  <div className="body-wrapper">
    <div className="screen-wrapper flex-wrap flex-dire">
      <Donation symbol={props.symbol}></Donation>
    </div>
  </div>
);
}

export default App;