import React, { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import Checkout from "./checkout";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Main(props) {
  const [captchaToken, setCaptchaToken] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [paymentMethodType] = useState("card");
  const [clientSecret, setClientSecret] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("yourAction");
    if (token) {
      setCaptchaToken(token);
      getClientSecrete(token);
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const getClientSecrete = async (token) => {
    let final = 6 * 100;
   setLoading(true);
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + "/v1/stripe/payment-intent", {
        amount: final,
        paymentMethodType: paymentMethodType,
        automaticPaymentMethods: true,
        captcha: token,
        coverFees: false,
        charity: "61a6ce503639c83ee249eff7",
        firstName: "Apple",
        lastName: "Developer",
        email: "test@gmail.com",
      })
      .then(async (response) => {
        if (response.data.id !== "" && response.data.clientSecret !== "") {
          localStorage.removeItem("_id:");
          localStorage.setItem("_id:", response.data.id);
          setClientSecret(response.data.clientSecret);
         setLoading(false);
        } // Handle data
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          localStorage.clear();
          setResponseMessage(
            typeof error === "string" ? error : error.response.data.message
          );
          setTimeout(() => {
            setResponseMessage("");
          }, 5000);
          return;
        }
      });
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      {responseMessage !== "" && (
        <p style={{ color: "red" }}>{responseMessage}</p>
      )}
      {clientSecret && stripePromise && (
         <Elements stripe={stripePromise} options={{ clientSecret }}>
          <Checkout
            {...props}
            captchaToken={captchaToken}
            clientSecret={clientSecret}
          />
        </Elements>
      )}
    </>
  );
}
