import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87BBFD',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },
  showIcon: false,
};

export default function Checkout(props) {
  // Pages
  const [showAmount, setShowAmount] = useState(false);
  const [showDetails ] = useState(false);
  const [showMethod, setShowMethod] = useState(true);
  const [showDonate, setShowDonate] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  
  //Amount
 
  // Details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");

  const [supportMessage, setSupportMessage] = useState("");

  // Method
  const [loading, setLoading] = useState(false);
  
  const [myDirectImpact, setMyDirectImpact] = useState(0);
  const [responseMessage, setResponseMessage] = useState("");

  const [finalDonate, setFinalDonate] = useState(0);

  // Donate
  const [maximizeImpact, setmaximizeImpact] = useState(false);
  const [supportLittlePhil] = useState(2.75);

  const stripe = useStripe();
  const elements = useElements();

  // Thanks 
  const [chargedAmount, setChargedAmount] = useState(0);

  // show Active Previous Tab
  const [paymentElementValidation, setPaymentElementValidation] = useState(false);
  const [paymentMethodType] = useState("card");


  useEffect(() => {
   
    if (showMethod) {
      setLocalStorageItem();
      if (localStorage.getItem("donateAmount") !== null) {
        setMyDirectImpact(parseInt(localStorage.getItem("donateAmount")));
        setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
      }
    }

    if (showDonate) {
      if (localStorage.getItem("donateAmount") !== null) {
        setMyDirectImpact(parseInt(localStorage.getItem("donateAmount")));
        setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
      }
    }
  }, []);

  const setLocalStorageItem = () =>{
      if (localStorage.getItem("firstName") !== null) {
          setFirstName(localStorage.getItem("firstName"));
        }
        if (localStorage.getItem("lastName") !== null) {
          setLastName(localStorage.getItem("lastName"));
        }
        if (localStorage.getItem("emailId") !== null) {
          setEmailId(localStorage.getItem("emailId"));
        }
        if (localStorage.getItem("supportMessage") !== null) {
          setSupportMessage(localStorage.getItem("supportMessage"));
        }
  }

  const handleFormValidation = () => {
    let formIsValid = true;

    if (showMethod) {
      return formIsValid;
    }

    if (showDonate) {
      setMyDirectImpact(parseInt(localStorage.getItem("donateAmount")));
      setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
    }
  };

  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      
      if (showMethod) {
        handleFormValidation();
      }

      if (showDonate) {
        if (name === "maximizeImpact") {
          setmaximizeImpact(e.target.checked);
          if (e.target.checked) {
            let finalDonateAmount = myDirectImpact + supportLittlePhil;
            setFinalDonate(finalDonateAmount);
          } else {
            setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
          }
          localStorage.setItem("maximizeImpact", e.target.checked);
        }
      }
    } else {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (showMethod) {
      if (paymentElementValidation) {
        if (localStorage.getItem("donateAmount") !== null) {
          setMyDirectImpact(parseInt(localStorage.getItem("donateAmount")));
          setFinalDonate(parseInt(localStorage.getItem("donateAmount")));
          setShowMethod(false);
          setShowDonate(true);
        } else {
          setShowMethod(false);
          setShowAmount(true);
        }
      }
    }

    if (showDonate) {
      setLocalStorageItem();
      if (!stripe || !elements) {
        return;
      }
      if (localStorage.getItem("_id:") !== null) {
        setLoading(true);
        let url =
          process.env.REACT_APP_API_BASE_URL +
          "/v1/stripe/payment-intent/" +
          localStorage.getItem("_id:");
        
        let final = 0; 
        if(maximizeImpact){ final = (myDirectImpact + supportLittlePhil)  * 100;
        }else{ final = myDirectImpact  * 100; }

        await axios
          .patch(url, {
            amount: final,
            paymentMethodType: paymentMethodType,
            //automaticPaymentMethods: true,
            //captcha: props.captchaToken,
            coverFees: false,
            // charity: '61a6ce503639c83ee249eff7',
            firstName: firstName,
            lastName: lastName,
            email: emailId,
            clientSecret: props.clientSecret,
          })
          .then(async (response) => {
            if (response.data.id !== "" && response.data.clientSecret !== "") {
              localStorage.clear();
              localStorage.setItem(
                "chargedAmount:",
                response.data.metadata.chargedAmount
              );
              setChargedAmount(parseInt(response.data.metadata.chargedAmount)/100);
              
              const response11 = await stripe.confirmPayment({
                elements,
                confirmParams: {
                 },
                redirect: 'if_required'
               });
               
               if (response11.error) {
                setResponseMessage(response11.error.message);
               } else {
                setShowDonate(false); setShowThanks(true);   
                //setResponseMessage(`Payment Succeeded: ${response11.paymentIntent.id}`);
               }
               setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error) {
              setResponseMessage(
                typeof error === "string" ? error : error.response.data.message
              );
              setTimeout(() => {
                setResponseMessage("");
              }, 5000);
              return;
            }
          });
      } else {
        setResponseMessage("Something goes wrong.");
      }
    }
    if (showThanks) {
      setShowThanks(false);
      setShowAmount(true);
    }
  };

  const onPaymentElementChange = (e) => {
    setPaymentElementValidation(e.complete);
  };

  return (
    <>
      <div className="top-menu flex-display">
          <span
          className={
              showAmount
              ? "active "
              : (!showDetails && showMethod) ||
                  (showDetails && !showMethod) ||
                  (!showAmount && !showDetails && !showMethod)
              ? "is-tab-active"
              : ""
          }
          >
          1. Amount{" "}
          </span>
          <span
          className={
              showDetails
              ? "active "
              : (!showAmount && !showDetails && !showMethod) ||
                  (!showAmount && showMethod)
              ? "is-tab-active"
              : ""
          }
          >
          2. Details{" "}
          </span>
          <span
          className={
              showMethod
              ? "active "
              : ((!showAmount && !showDetails && !showMethod && showDonate) || (!showDonate && showThanks))
              ? "is-tab-active"
              : ""
          }
          >
          3.Method{" "}
          </span>
          <span
          className={showDonate || showThanks ? "active" : ""}
          >
          {" "}
          4. Donate{" "}
          </span>
      </div>  

      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}

      <div
        className={
          showDonate
            ? "flex-wrap flex-dire flex-bet flex-grow"
            : showThanks
            ? "flex-wrap flex-dire flex-bet flex-grow thankyou-bg"
            : "height100"
        }
      >

        <div
          className={
            showDonate
              ? "flex-wrap flex-dire flex-bet flex-grow height100"
              : "flex-wrap flex-dire flex-bet flex-grow height100"
          }
        >

          {showMethod && (
             <div className="head-title">
                <h1>Donate with</h1>
                <p>Select your donation method.</p>
            </div>
          )}
          {showDonate && (
          <div className="head-title">
            <h1>Your donation</h1>
            <p>Finalise your donation.</p>
          </div>
          )}

        {showThanks && <div className="head-title">
            <h1>Thank you!</h1>
            <p>Enjoy this moment.</p>
        </div>}
         
          {responseMessage !== "" && (
            <p style={{ color: "red" }}>{responseMessage}</p>
          )}

          {(showMethod || showDonate) && (
            <div className="">
              <div className={showDonate ? "opacity-0" : ""}>
                <PaymentElement
                  id="payment-element"
                  onChange={onPaymentElementChange}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </div>

              {showDonate && (
                <div className="flex-wrap flex-gap-10 donation-info mb10">
                  <div className="flex-display">
                    <div className="user-pic">
                    </div>
                  </div>
                  <div className="flex-grow">
                    <h2>Currumbin Wildlife Hospital</h2>
                  </div>
                </div>
              )}
              {showDonate && (
                <div className="flex-wrap mb10">
                  <p className="font12">
                    Your donation directly supports this cause.
                  </p>
                </div>
              )}

              {showDonate && (
                <div className="flex-wrap flex-bet mb20">
                  <p>Maximise impact</p>
                  <div className="">
                    <input
                      type="checkbox"
                      hidden="hidden"
                      id="maximizeImpact"
                      name="maximizeImpact"
                      value={maximizeImpact}
                      onChange={handleChange}
                      checked={maximizeImpact}
                    />
                    <label className="switch" htmlFor="maximizeImpact"></label>
                  </div>
                </div>
              )}

              {showDonate && <div className="divider mb20"></div>}

              {showDonate && (
                <div className="flex-wrap flex-bet mb20">
                  <p>My direct impact</p>
                  <div className="price">
                    <span>${myDirectImpact}</span>
                  </div>
                </div>
              )}
              {showDonate && (
                <div className="flex-wrap flex-bet mb20">
                  <p>Support Little Phil</p>
                  <div className="price">
                    <span>${supportLittlePhil}</span>
                  </div>
                </div>
              )}
              {showDonate && <div className="divider mb20"></div>}
              {showDonate && (
                <div className="flex-wrap flex-bet">
                  <div className="">
                    <p className="font-bold">Donation total</p>
                    <span className="font12 clear-gray">
                      Your donation is tax deductible
                    </span>
                  </div>
                  <div className="price">
                    <span className="font-bold">${finalDonate}</span>
                  </div>
                </div>
              )}
            </div>
          )}

        {showThanks && 
          <div className="">
             <div className="flex-display flex-dire border1px radius10px donation-info p40-tb p20-lr align-center thankyou">
              <div className="thankyou-up mb10"></div>
              <h2 className="font-bold mb5">Currumbin Wildlife Hospital</h2>
              <p className="width60 text-center mb5">Your funds are being directly wired to this cause</p>
              <div className="font-bold">${chargedAmount}</div>
              <span>RAISED</span>
            </div>
          </div>
        }

          {!showThanks && <div className="flex-wrap flex-bet">
            {!showDonate && (
              <div className="">
                {showMethod && (
                  <button
                    className="org-btn org-btn-dis"
                    id="submit"
                    onClick={handleSubmit}
                    disabled={!paymentElementValidation}
                  >
                    continue
                  </button>
                )}
                {!showMethod && (
                  <button className="org-btn" onClick={handleSubmit}>
                    continue
                  </button>
                )}
              </div>
            )}

            {showMethod && <div className="flex-wrap justify-content">
              <div className="app-logo-bg"></div>
            </div>}
                {showDonate && (
                  <div className="width100">
                    <button className="org-btn big-btn" onClick={handleSubmit}>
                      Donate ${finalDonate}
                    </button>
                  </div>
                )}
              </div>}


          {showThanks && <div className="flex-wrap flex-bet align-center">
            <div className="mx-auto">
                <div className="app-logo-bg"></div>
            </div>
          </div> }
        </div>
      </div>
    </>
  );
}
